<template>
  <div class="pump-video ly-wrap">
    <div class="currency-title" style="background-color: #ffffff;"><span class="back" @click="goBack()"></span>视频监控
    </div>
    <div class="video-wrap" style="width: 100%;height: 8.5rem;">
      <EZUIKitJs :setObj="setObj"/>
    </div>

    <div class="tab-wrap">
      <div class="tab-box" v-for="(item,index) in videoList" :key="index">
        <div class="tab-item" @click="handleUrl(item.videoUrl)">
          <div class="btn ing" v-if="isUrl == item.videoUrl">正在播放</div>
          <div class="btn" v-else>播放</div>
          <div class="name">{{ item.name }}</div>
        </div>

        <div class="light-btn"
             @click="switchLight(item.variableId,item.pumpId,item.type)"><span
            v-if="item.type == '2'">照明控制</span><span
            v-if="item.type == '7'">声光报警</span></div>
      </div>

      <div class="clear" style="clear: both"></div>

    </div>


    <div class="back-btn" @click="handleRefresh" v-track-event="'刷新页面, 点击'">
      <div class="icon"></div>
      刷新页面
    </div>

  </div>
</template>

<script>

import EZUIKitJs from '../../components/EZUIKitJs.vue'
import {postPumpView, postVariableData, getCurveData, getVideoList} from "../../api/api";
import {getSelectPump} from '@/utils/auth'
import {Toast} from "mint-ui";
import store from "../../store";

export default {
  name: 'Video',
  components: {EZUIKitJs},
  computed: {},
  data() {
    return {
      isType: 'security',
      isUrl: 'ezopen://open.ys7.com/G04996581/1.live',
      setObj: {
        type: 'security',
        url: 'ezopen://open.ys7.com/G04996581/1.live',
      },
      videoList: [
        {
          "deviceSerial": "G04996581",
          "pumpId": "1440996711858720769",
          "variableName": "L1_OUT",
          "videoUrl": "ezopen://open.ys7.com/G04996581/1.live",
          "name": "1号摄像头",
          "variableData": "0",
          "type": "2",
          "variableId": "1440996255501668354"
        },
        {
          "deviceSerial": "G04996581",
          "pumpId": "1440996711858720769",
          "variableName": "L2_OUT",
          "videoUrl": "ezopen://open.ys7.com/G04996581/2.live",
          "name": "2号摄像头",
          "variableData": "0",
          "type": "2",
          "variableId": "1440996367556694017"
        }
      ]
    }
  },
  mounted() {
  },
  created() {
    this.loadBase()
  },
  methods: {
    loadBase() {
      getVideoList({waterId: '1440990248591446018'}).then(res => {
        this.videoList = res.data.result
      })
    },
    // 开关指示灯控制
    switchLight(id, pumpId, typeCode) {
      getCurveData({variableId: id}).then(res => {
        let dataBefore = res.data.result[0].variableData
        let data = '0'
        if (dataBefore == '0') {
          data = '1'
        }
        this.postData(id, data, dataBefore, pumpId, typeCode)
      })
    },
    // 统一的控制请求
    postData(variableId, data, dataBefore, pumpId, typeCode) {
      const body = {
        variableId: variableId,
        data: data,
        dataBefore: dataBefore,
        pumpId: pumpId,
        typeCode: typeCode,
      }
      postVariableData(body).then(res => {
        Toast('操作成功！');
      })
    },
    handleUrl(url) {
      this.isUrl = url
      this.setObj.url = url
    },
    handleType(type) {
      this.isType = type
      this.setObj.type = type
    },
    handleRefresh() {
      this.$router.go(0)
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">

.pump-video {
  width: 100%;
  height: 100%;
  background-color: #4D4D4DFF;
}

.video-wrap {
  background-color: #3D3D3DFF;
}

.tab-wrap {
  width: 100%;
  min-height: 3.5rem;


  .tab-box {
    width: 2.2rem;
    height: 3.5rem;
    float: left;
    margin-left: 0.235rem;
    margin-bottom: 0.3rem;

    .tab-item {
      width: 2.2rem;
      height: 1.9rem;
      background: url("~@/assets/img/vidoe-bg.png") no-repeat top center;
      background-size: 2.2rem;
      color: #FFFFFF;
      text-align: center;
      padding-top: 0.35rem;

      .btn {
        width: 1.2rem;
        height: 0.6rem;
        line-height: 0.6rem;
        margin: 0 auto 0.3rem;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
      }

      .ing {
        width: 100%;
        border: none;
      }

      .name {
        font-size: 0.28rem;
      }
    }

    .light-btn {
      width: 2.2rem;
      height: 0.88rem;
      line-height: 0.88rem;
      text-align: center;
      border: 1px solid #ffffff;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 0.24rem;
      color: #ffffff;
      margin-top: 0.25rem;
    }
  }

}

.back-btn {
  width: 7rem;
  margin: 0.3rem auto;
  height: 0.88rem;
  background: #4D4D4D;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.36rem;
  line-height: 0.88rem;

  .icon {
    width: 0.43rem;
    height: 0.43rem;
    background: url("~@/assets/img/refresh.png") no-repeat center;
    background-size: 0.42rem;
    position: relative;
    top: 0.08rem;
    display: inline-block;
  }
}

@import '~@/assets/less/view.less';
</style>

